export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'K/L',
      field: 'instansi_prefix',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Aplikasi / Infrastruktur',
      field: 'nama_app_infra',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Aplikasi / Infrastruktur',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'total_anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Perubahan Terakhir',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Hari Dalam Antrian',
      field: 'wait_time',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Rekomendasi Direktur',
      field: 'status',
      width: '160px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'K/L',
      field: 'instansi_prefix',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Item Belanja',
      field: 'nama',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Item Belanja',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'total_anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Permohonan',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Hari Dalam Antrian',
      field: 'wait_time',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Rekomendasi Direktur',
      field: 'status',
      width: '160px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
